var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.singleProductDashboardErrorMessage ? _c('div', {
    staticClass: "mt-6 pa-4"
  }, [_c('r-error-message', {
    attrs: {
      "errors": [_vm.singleProductDashboardErrorMessage]
    }
  })], 1) : _vm._e(), _c('products-dropdown', {
    attrs: {
      "select-label": "Add products",
      "preselected-products": _vm.preselectedProductIds
    },
    on: {
      "change": _vm.onSelectProducts
    }
  }), _c('div', {
    staticClass: "d-flex justify-end py-4 pr-4"
  }, [_c('r-button', {
    staticClass: "mr-2",
    attrs: {
      "outlined": "",
      "label": "Cancel"
    },
    on: {
      "click": _vm.onClose
    }
  }), _c('r-button', {
    attrs: {
      "disabled": _vm.disableSaveButton,
      "label": "Save"
    },
    on: {
      "click": _vm.onAddDashboards
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }