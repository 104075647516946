import { render, staticRenderFns } from "./ProductSelectionPopup.vue?vue&type=template&id=655fa1d0&scoped=true&"
import script from "./ProductSelectionPopup.vue?vue&type=script&lang=js&"
export * from "./ProductSelectionPopup.vue?vue&type=script&lang=js&"
import style0 from "./ProductSelectionPopup.vue?vue&type=style&index=0&id=655fa1d0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "655fa1d0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VRating } from 'vuetify/lib/components/VRating';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VRating,VRow})
