var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "productsentimentcomparison"
  }, [_c('v-row', [_c('v-col', [_c('r-component-header', {
    staticClass: "productsentimentcomparison--header-border",
    scopedSlots: _vm._u([{
      key: "end",
      fn: function fn() {
        return [_c('r-anchored-popup', {
          ref: "productSelectionPopup",
          attrs: {
            "label": "Select Products"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref) {
              var onClose = _ref.onClose;
              return [_c('product-selection-popup', {
                attrs: {
                  "preselected-sort": _vm.sort,
                  "preselected-sort-dir": _vm.sortDir,
                  "preselected-product-ids": _vm.selectedProducts
                },
                on: {
                  "change:selected-products": _vm.onChangeSelectedProduct,
                  "onClose": onClose
                }
              })];
            }
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('span', {
    staticClass: "px-1"
  }, [_vm._v(" Sentiment Comparison ")])])], 1)], 1), _vm.fetchingProductsSentiment ? _c('v-row', _vm._l(10, function (n) {
    return _c('v-col', {
      key: n,
      staticClass: "productsentimentcomparison--loader pa-0",
      attrs: {
        "cols": "12"
      }
    }, [_c('v-skeleton-loader', {
      attrs: {
        "type": "image",
        "width": "100%",
        "height": "25"
      }
    })], 1);
  }), 1) : _vm.noData ? _c('v-row', [_c('v-col', [_c('p', [_vm._v("No products are selected yet! Select products for sentiment comparison")])])], 1) : _vm._l(_vm.products, function (productData) {
    return _c('v-row', {
      key: productData.productId,
      staticClass: "mx-0 mt-4 mb-1"
    }, [_c('r-stacked-bar-chart', {
      attrs: {
        "properties": ['positive', 'neutral', 'negative'],
        "data": productData
      }
    })], 1);
  }), _c('v-row', {
    staticClass: "productsentimentcomparison-legends ma-0 mb-1",
    attrs: {
      "justify": "space-between"
    }
  }, [_c('v-col', _vm._l(['Positive', 'Neutral', 'Negative'], function (sentiment) {
    return _c('p', {
      key: "".concat(sentiment),
      staticClass: "d-inline mx-2"
    }, [_c('v-icon', {
      style: _vm.legendStyle(sentiment),
      attrs: {
        "small": ""
      }
    }, [_vm._v(" fiber_manual_record ")]), _vm._v(" " + _vm._s(sentiment) + " ")], 1);
  }), 0), _c('v-col', [_c('r-pagination', {
    attrs: {
      "page": _vm.page,
      "total-pages": Math.ceil(_vm.totalProducts / _vm.pageSize),
      "justify": "end"
    },
    on: {
      "click:page": _vm.onClickPage
    }
  })], 1)], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }