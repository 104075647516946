var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "productstable"
  }, [_c('v-row', [_c('v-col', [_c('r-component-header', {
    staticClass: "productstable--header-border",
    scopedSlots: _vm._u([{
      key: "end",
      fn: function fn() {
        return [_c('r-anchored-popup', {
          ref: "productSelectionPopup",
          attrs: {
            "label": "Select Products"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref) {
              var onClose = _ref.onClose;
              return [_c('product-selection-popup', {
                attrs: {
                  "preselected-sort": _vm.sort,
                  "preselected-sort-dir": _vm.sortDir,
                  "preselected-product-ids": _vm.selectedProducts
                },
                on: {
                  "change:selected-products": _vm.onChangeSelectedProducts,
                  "onClose": onClose
                }
              })];
            }
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('span', {
    staticClass: "px-1"
  }, [_vm._v(" Dashboard Products ")])])], 1)], 1), _vm.noData ? _c('v-row', [_c('v-col', [_c('p', [_vm._v("No products are selected yet! Select products for products comparison")])])], 1) : _c('r-table', {
    staticClass: "productstable--table",
    attrs: {
      "is-selectable": false,
      "columns": _vm.tableColumns,
      "rows": _vm.products,
      "total-rows": _vm.totalProducts,
      "page": _vm.page,
      "page-size": _vm.pageSize,
      "loading": _vm.fetchingProductsOfTable
    },
    on: {
      "change:page": _vm.onClickPage
    },
    scopedSlots: _vm._u([{
      key: "item.name",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('span', {
          staticClass: "productstable--name text-truncate",
          attrs: {
            "title": item.name
          }
        }, [_vm._v(" " + _vm._s(item.name) + " ")])];
      }
    }, {
      key: "item.averageRating",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "d-flex justify-start align-center"
        }, [_c('span', [_vm._v(_vm._s(parseFloat(item.averageRating.toFixed(1))))]), _c('v-rating', {
          attrs: {
            "length": "1",
            "color": "yellow darken-2",
            "background-color": "yellow darken-2"
          },
          model: {
            value: item.averageRating,
            callback: function callback($$v) {
              _vm.$set(item, "averageRating", $$v);
            },
            expression: "item.averageRating"
          }
        })], 1)];
      }
    }, {
      key: "item.numReviews",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('div', {
          staticClass: "d-flex justify-start align-center"
        }, [_c('span', [_vm._v(_vm._s(item.numReviews))])])];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }