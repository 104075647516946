var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "productselectionpopup pa-4 pt-0"
  }, [_c('div', {
    staticClass: "d-flex align-center mb-4"
  }, [_c('span', {
    staticClass: "mr-2"
  }, [_vm._v("Sort by")]), _c('r-select', {
    staticStyle: {
      "width": "226px"
    },
    attrs: {
      "items": _vm.sortOptions,
      "show-search": false
    },
    on: {
      "change": _vm.onSortByChanged
    }
  })], 1), _c('v-row', {
    staticClass: "ma-0 mb-4"
  }, [_c('r-search-input', {
    attrs: {
      "value": _vm.search,
      "placeholder": "Search"
    },
    on: {
      "change": _vm.onChangeSearch
    }
  })], 1), _c('r-error-message', {
    staticClass: "mb-4",
    attrs: {
      "errors": _vm.errors
    }
  }), _c('r-table', {
    staticClass: "productstable--table",
    attrs: {
      "columns": _vm.tableColumns,
      "rows": _vm.productItems,
      "total-rows": _vm.totalProducts,
      "page": _vm.page,
      "page-size": _vm.pageSize,
      "loading": _vm.fetchingProducts
    },
    on: {
      "change:page": function changePage(_ref) {
        var value = _ref.value;
        return _vm.setPage({
          value: value
        });
      },
      "change:page-size": _vm.onChangePageSize,
      "change:batch-select": _vm.onBatchSelect,
      "change:batch-select-all": _vm.onBatchSelectAll
    },
    scopedSlots: _vm._u([{
      key: "item.name",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('span', {
          staticClass: "productstable--name text-truncate",
          attrs: {
            "title": item.name
          }
        }, [_vm._v(" " + _vm._s(item.name) + " ")])];
      }
    }, {
      key: "item.averageRating",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "d-flex justify-start align-center"
        }, [_c('span', [_vm._v(_vm._s(parseFloat(item.averageRating.toFixed(1))))]), _c('v-rating', {
          attrs: {
            "length": "1",
            "color": "yellow darken-2",
            "background-color": "yellow darken-2"
          },
          model: {
            value: item.averageRating,
            callback: function callback($$v) {
              _vm.$set(item, "averageRating", $$v);
            },
            expression: "item.averageRating"
          }
        })], 1)];
      }
    }, {
      key: "item.numReviews",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('div', {
          staticClass: "d-flex justify-start align-center"
        }, [_c('span', [_vm._v(_vm._s(item.numReviews))])])];
      }
    }])
  }), _c('div', {
    staticClass: "d-flex mt-4 justify-end productsselectionpopup--button--container"
  }, [_c('r-button', {
    staticClass: "productsselectionpopup--button",
    staticStyle: {
      "width": "123px"
    },
    attrs: {
      "outlined": true
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('onClose');
      }
    }
  }, [_vm._v(" Cancel ")]), _c('r-button', {
    staticClass: "productsselectionpopup--button",
    staticStyle: {
      "width": "123px"
    },
    attrs: {
      "disabled": _vm.hasErrors
    },
    on: {
      "click": _vm.onClickApply
    }
  }, [_vm._v(" Apply ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }