var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('r-modal', {
    staticClass: "savedashboardasmodal",
    attrs: {
      "title": _vm.$t('dashboards.saveAs.title'),
      "close-action-label": _vm.$t('global.actions.cancel'),
      "action-label": _vm.$t('dashboards.createDashboard.createDashboardBtn'),
      "fetching": _vm.fetchingSaveDashboardAs,
      "error-message": _vm.saveDashboardAsError
    },
    on: {
      "close": _vm.onClose,
      "submit": _vm.onClickSave
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('r-text-field', {
          staticClass: "mb-2",
          attrs: {
            "value": _vm.name,
            "label": _vm.$t('dashboards.createDashboard.name'),
            "placeholder": "Samsung S10"
          },
          on: {
            "change": _vm.onChangeName
          }
        }), _c('label', [_vm._v("Add to project")]), _c('project-select', {
          on: {
            "change": _vm.onChangeProject
          }
        })];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }