var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "singleproductdashboard"
  }, [_c('div', {
    staticClass: "pa-0"
  }, [_c('div', {
    staticClass: "px-14"
  }, [_c('div', {
    staticClass: "header"
  }, [_c('div', {
    staticClass: "py-6"
  }, [_c('dashboard-header', {
    staticClass: "singleproductdashboard--header",
    attrs: {
      "pinned": _vm.pinned,
      "name": _vm.name,
      "show-reviews": _vm.showReviews
    },
    on: {
      "show:reviews": _vm.onShowReviews
    }
  })], 1), _c('v-row', {
    staticClass: "singleproductdashboard--tabs ma-0 mt-2"
  }, [_c('v-col', {
    staticClass: "pa-0"
  }, [_c('r-grouped-tabs', {
    attrs: {
      "selected-group-tab": _vm.selectedGroupTab,
      "selected-sub-tab": _vm.selectedSubTab,
      "items": _vm.SINGLE_PRODUCT_GROUPED_TABS
    },
    on: {
      "change": _vm.onChangeGroupTab,
      "change:sub-tab": _vm.onChangeSubTab
    }
  })], 1)], 1)], 1), !_vm.fetchingDashboardsData ? _c('v-row', {
    staticClass: "singleproductdashboard--filter-row mx-0"
  }, [_c('v-col', {
    staticClass: "pa-0 mb-3",
    attrs: {
      "cols": "6"
    }
  }, [_c('dashboard-date-selector', {
    staticClass: "d-flex",
    attrs: {
      "start-date": _vm.startDate,
      "end-date": _vm.endDate
    },
    on: {
      "change": _vm.onChangeDateRange
    }
  })], 1), _c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('dashboard-filter-chips', {
    staticClass: "d-flex flex-wrap flex-row-reverse"
  })], 1)], 1) : _c('v-row', {
    staticClass: "singleproductdashboard--filter-row mx-0"
  }, [_c('v-skeleton-loader', {
    staticClass: "elevation-0 filterrow--loader",
    attrs: {
      "type": "list-item",
      "height": "35",
      "width": "100%"
    }
  })], 1)], 1), _c('v-divider')], 1), _c('single-product-dashboard-content', {
    ref: "dashboardcontent",
    attrs: {
      "selected-group-tab": _vm.selectedGroupTab,
      "selected-sub-tab": _vm.selectedSubTab,
      "show-reviews": _vm.showReviews
    },
    on: {
      "on:show-reviews": _vm.onShowReviews
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }