var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dashboardfilterchips"
  }, [_vm._l(_vm.renderedFilters, function (filter) {
    return _c('r-chip', {
      key: "dashboardfilterchips-".concat(filter.key),
      staticClass: "dashboardfilterchips--chip ma-1",
      attrs: {
        "label": filter.label,
        "disabled": _vm.fetchingDashboardsData,
        "removable": ""
      },
      on: {
        "click": function click($event) {
          return _vm.onClickRemove([filter.key]);
        }
      }
    });
  }), _vm.entityGroupName ? _c('r-chip', {
    key: "dashboardfilterchips-entitygroup",
    staticClass: "dashboardfilterchips--chip ma-1",
    attrs: {
      "label": "Reviews with entity group '".concat(_vm.entityGroupName.toLowerCase(), "'"),
      "disabled": _vm.fetchingDashboardsData,
      "removable": ""
    },
    on: {
      "click": function click($event) {
        return _vm.onClickRemove(['entityGroupId']);
      }
    }
  }) : _vm._e(), _vm.sentimentRangeKey ? _c('r-chip', {
    key: "dashboardfilterchips-sentiment",
    staticClass: "dashboardfilterchips--chip ma-1",
    attrs: {
      "label": _vm.sentimentFilterLabel,
      "disabled": _vm.fetchingDashboardsData,
      "removable": ""
    },
    on: {
      "click": function click($event) {
        return _vm.onClickRemove(['sentiment']);
      }
    }
  }) : _vm._e(), _vm.entitySentimentRangeKey ? _c('r-chip', {
    key: "dashboardfilterchips-entity-sentiment",
    staticClass: "dashboardfilterchips--chip ma-1",
    attrs: {
      "label": _vm.entitySentimentFilterLabel,
      "disabled": _vm.fetchingDashboardsData,
      "removable": ""
    },
    on: {
      "click": function click($event) {
        return _vm.onClickRemove(['entitySentiment']);
      }
    }
  }) : _vm._e(), _vm.dateRange ? _c('r-chip', {
    key: "dashboardfilterchips-date",
    staticClass: "dashboardfilterchips--chip ma-1",
    attrs: {
      "label": _vm.dateRangeLabel,
      "disabled": _vm.fetchingDashboardsData,
      "removable": ""
    },
    on: {
      "click": function click($event) {
        return _vm.onClickRemove(['dateRange']);
      }
    }
  }) : _vm._e(), _vm.entityMapIsApplied ? _c('r-chip', {
    key: "dashboardfilterchips-map",
    staticClass: "dashboardfilterchips--chip ma-1",
    attrs: {
      "label": _vm.entityMapLabel,
      "disabled": _vm.fetchingDashboardsData,
      "removable": ""
    },
    on: {
      "click": function click($event) {
        return _vm.onClickRemove(['entityMapId']);
      }
    }
  }) : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }