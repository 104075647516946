var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "productentitiescomparison"
  }, [_vm.fetchingDashboardsData || _vm.fetchingProductsEntities ? _c('v-row', _vm._l(10, function (n) {
    return _c('v-col', {
      key: n,
      staticClass: "productentitiescomparison-loader pa-0 mb-5",
      attrs: {
        "cols": "12"
      }
    }, [_c('v-skeleton-loader', {
      attrs: {
        "type": "image",
        "width": "100%",
        "height": "25"
      }
    })], 1);
  }), 1) : !_vm.productsEntities.length ? _c('v-row', [_c('v-col', {
    staticClass: "productentitiescomparison--no-data",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center justify-space-between"
  }, [_c('r-no-results-message'), _c('r-anchored-popup', {
    ref: "productentitiescomparison-product-selector",
    attrs: {
      "label": "Select Products"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var onClose = _ref.onClose;
        return [_c('product-selection-popup', {
          attrs: {
            "preselected-sort": _vm.sort,
            "preselected-sort-dir": _vm.sortDir,
            "preselected-product-ids": _vm.selectedProducts
          },
          on: {
            "change:selected-products": _vm.onChangeSelectedProducts,
            "onClose": onClose
          }
        })];
      }
    }])
  })], 1), _c('v-divider')], 1)], 1) : _vm._l(_vm.productsEntities, function (entityData, index) {
    return _c('v-row', {
      key: "".concat(entityData.entity, "-").concat(index),
      staticClass: "pa-0",
      attrs: {
        "cols": "12"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "12"
      }
    }, [_c('r-component-header', {
      staticClass: "productentitiescomparison--header-border"
    }, [_c('r-tabs', {
      staticClass: "productentitiescomparison--tabs d-flex",
      staticStyle: {
        "width": "100%"
      },
      attrs: {
        "items": _vm.tabItems,
        "selected-tab": _vm.selectedTabByEntity[entityData.entity]
      },
      on: {
        "change": function change(i) {
          return _vm.onChangeEntitySelectedTab({
            entity: entityData.entity,
            selectedTab: i
          });
        }
      },
      scopedSlots: _vm._u([{
        key: "start",
        fn: function fn() {
          return [_c('p', {
            staticClass: "productentitiescomparison--tab-title--wrapper"
          }, [_c('span', {
            staticClass: "productentitiescomparison--tab-title text-truncate px-1",
            attrs: {
              "title": entityData.entity
            }
          }, [_vm._v(" " + _vm._s(entityData.entity) + " ")]), _c('span', {
            staticClass: "productentitiescomparison--tab-title--total"
          }, [_vm._v(" (" + _vm._s(_vm.productsEntitiesBreakdownTotal(entityData)) + ") ")])])];
        },
        proxy: true
      }, {
        key: "end",
        fn: function fn() {
          return [_c('v-row', {
            staticClass: "productentitiescomparison-product-selector ma-0"
          }, [_c('r-anchored-popup', {
            ref: "productentitiescomparison-product-selector",
            refInFor: true,
            attrs: {
              "label": "Select Products"
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref2) {
                var onClose = _ref2.onClose;
                return [_c('product-selection-popup', {
                  attrs: {
                    "preselected-sort": _vm.sort,
                    "preselected-sort-dir": _vm.sortDir,
                    "preselected-product-ids": _vm.selectedProducts
                  },
                  on: {
                    "change:selected-products": _vm.onChangeSelectedProducts,
                    "onClose": onClose
                  }
                })];
              }
            }], null, true)
          })], 1)];
        },
        proxy: true
      }], null, true)
    })], 1), _c('r-tab-items', {
      staticClass: "mr-4 mt-4",
      staticStyle: {
        "width": "100%",
        "min-height": "730px"
      },
      attrs: {
        "selected-tab": _vm.selectedTabByEntity[entityData.entity]
      }
    }, [_c('r-tab-item', {
      attrs: {
        "label": "Rating"
      }
    }, [_c('r-error-message', {
      staticClass: "my-2",
      attrs: {
        "errors": [_vm.fetchProductsEntitiesError]
      }
    }), _vm.noData ? _c('v-row', [_c('v-col', [_c('p', [_vm._v(" " + _vm._s(_vm.$t('dashboards.multiProduct.message.noData', {
      type: 'rating'
    })) + " ")])])], 1) : _vm._l(_vm.productsEntitiesRatingBreakdown({
      data: entityData.products
    }), function (productData) {
      return _c('v-row', {
        key: productData.productId,
        staticClass: "mx-0 mt-4 mb-1"
      }, [_c('r-stacked-bar-chart', {
        attrs: {
          "properties": _vm.RATING_KEYS,
          "data": productData
        }
      })], 1);
    }), _c('v-row', {
      staticClass: "ma-0 mb-1",
      attrs: {
        "justify": "space-between"
      }
    }, [_c('v-col', _vm._l([5, 4, 3, 2, 1], function (i) {
      return _c('p', {
        key: "".concat(i),
        staticClass: "d-inline mx-2"
      }, [_c('v-icon', {
        style: _vm.legendStyleFromIndex(i),
        attrs: {
          "small": ""
        }
      }, [_vm._v(" fiber_manual_record ")]), _vm._v(" " + _vm._s(_vm.$t("global.keys.".concat(i))) + " ")], 1);
    }), 0), _c('v-col', [_c('r-pagination', {
      attrs: {
        "page": _vm.page,
        "total-pages": Math.ceil(_vm.totalProductsEntities / _vm.pageSize),
        "justify": "end"
      },
      on: {
        "click:page": _vm.onClickPage
      }
    })], 1)], 1)], 2), _c('r-tab-item', {
      attrs: {
        "label": "Sentiment"
      }
    }, [_c('r-error-message', {
      staticClass: "my-2",
      attrs: {
        "errors": [_vm.fetchProductsEntitiesError]
      }
    }), [_vm.noData ? _c('v-row', [_c('v-col', [_c('p', [_vm._v(" " + _vm._s(_vm.$t('dashboards.multiProduct.message.noData', {
      type: 'sentiment'
    })) + " ")])])], 1) : _vm._l(_vm.productsEntitiesSentimentBreakdown({
      data: entityData.products
    }), function (productData) {
      return _c('v-row', {
        key: productData.productId,
        staticClass: "mx-0 mt-4 mb-1"
      }, [_c('r-stacked-bar-chart', {
        attrs: {
          "properties": _vm.SENTIMENT_KEYS,
          "data": productData
        }
      })], 1);
    }), _c('v-row', {
      staticClass: "ma-0 mb-1",
      attrs: {
        "justify": "space-between"
      }
    }, [_c('v-col', _vm._l(_vm.SENTIMENT_KEYS, function (sentiment) {
      return _c('p', {
        key: "".concat(sentiment),
        staticClass: "d-inline mx-2"
      }, [_c('v-icon', {
        style: _vm.legendStyle(sentiment),
        attrs: {
          "small": ""
        }
      }, [_vm._v(" fiber_manual_record ")]), _vm._v(" " + _vm._s(_vm.$t("global.keys.".concat(sentiment))) + " ")], 1);
    }), 0), _c('v-col', [_c('r-pagination', {
      attrs: {
        "page": _vm.page,
        "total-pages": Math.ceil(_vm.totalProductsEntities / _vm.pageSize),
        "justify": "end"
      },
      on: {
        "click:page": _vm.onClickPage
      }
    })], 1)], 1)]], 2)], 1)], 1)], 1);
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }