var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "producthistorycomparison"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('r-tabs', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "items": _vm.tabItems,
      "selected-tab": _vm.selectedTab
    },
    on: {
      "change": function change(i) {
        return _vm.selectedTab = i;
      }
    },
    scopedSlots: _vm._u([{
      key: "start",
      fn: function fn() {
        return [_c('span', {
          staticClass: "producthistorycomparison--tab-title px-1"
        }, [_vm._v(" Single view chart ")])];
      },
      proxy: true
    }])
  }), _c('r-tab-items', {
    staticClass: "mr-4 mt-4",
    staticStyle: {
      "width": "100%",
      "min-height": "730px"
    },
    attrs: {
      "selected-tab": _vm.selectedTab
    }
  }, [_c('r-tab-item', {
    attrs: {
      "label": "Volumen"
    }
  }, [_c('r-error-message', {
    staticClass: "my-2",
    attrs: {
      "errors": [_vm.fetchProductsHistoryError]
    }
  }), _vm.fetchingProductsHistory ? [_c('v-skeleton-loader', {
    attrs: {
      "type": "image",
      "width": "100%",
      "height": "350"
    }
  })] : [_c('highcharts', {
    ref: "chart",
    staticClass: "hc",
    attrs: {
      "options": _vm.chartOptions
    }
  })]], 2), _c('r-tab-item', {
    attrs: {
      "label": "Rating"
    }
  }, [_c('r-error-message', {
    staticClass: "my-2",
    attrs: {
      "errors": [_vm.fetchProductsHistoryError]
    }
  }), _c('highcharts', {
    ref: "chart",
    staticClass: "hc",
    attrs: {
      "options": _vm.chartOptions
    }
  })], 1)], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "d-flex justify-end align-center"
  }, [_c('r-anchored-popup', {
    ref: "producthistorycomparison-product-selector",
    attrs: {
      "label": "Select Products"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var onClose = _ref.onClose;
        return [_c('product-selection-popup', {
          attrs: {
            "has-max-product-selection-limit": true,
            "preselected-sort": _vm.sort,
            "preselected-sort-dir": _vm.sortDir,
            "preselected-product-ids": _vm.selectedProductIds
          },
          on: {
            "change:selected-products": _vm.onChangeSelectedProducts,
            "onClose": onClose
          }
        })];
      }
    }])
  })], 1), _c('product-card-selector', {
    attrs: {
      "products": _vm.productsHistory,
      "selected-product-ids": _vm.highlightedItems,
      "hovered-product-id": _vm.hoveredItem,
      "loading": _vm.fetchingProductsHistory
    },
    on: {
      "change:highlighted-product": _vm.onChangeHighlightedProduct,
      "change:hovered-product": _vm.onChangeHoveredProduct
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }