<template>
  <div class="singlecategorydashboard">
    <div class="pa-0">
      <div class="px-14">
        <!-- Header and Tabs -->
        <div class="header">
          <div class="py-6">
            <dashboard-header
              class="singlecategorydashboard--header"
              :pinned="pinned"
              :name="name"
              :show-reviews="showReviews"
              @show:reviews="onShowReviews"
            />
          </div>

          <v-row class="singlecategorydashboard--tabs ma-0 mt-2">
            <v-col class="pa-0">
              <r-grouped-tabs
                :selected-group-tab="selectedGroupTab"
                :selected-sub-tab="selectedSubTab"
                :items="SINGLE_CATEGORY_GROUPED_TABS"
                @change="onChangeGroupTab"
                @change:sub-tab="onChangeSubTab"
              />
            </v-col>
          </v-row>
        </div>

        <!-- Filter and Chips -->
        <v-row
          v-if="!fetchingDashboardsData"
          class="singlecategorydashboard--filter-row mx-0 my-2"
        >
          <v-col
            class="pa-0 mb-3"
            cols="6"
          >
            <dashboard-date-selector
              class="d-flex"
              :start-date="startDate"
              :end-date="endDate"
              @change="onChangeDateRange"
            />
          </v-col>
          <v-col
            class="pa-0"
            cols="6"
          >
            <dashboard-filter-chips class="d-flex flex-wrap flex-row-reverse" />
          </v-col>
        </v-row>
        <v-row
          v-else
          class="singlecategorydashboard--filter-row mx-0"
        >
          <v-skeleton-loader
            class="elevation-0 filterrow--loader"
            type="list-item"
            height="35"
            width="100%"
          />
        </v-row>
      </div>

      <v-divider />
    </div>

    <v-row class="ma-0">
      <v-col
        ref="dashboardcontent"
        :cols="showReviews ? 9 : 12"
        class="dashboardcontent px-14 pt-6"
      >
        <v-row class="ma-0">
          <!-- Summary Tab -->
          <v-col
            v-if="selectedGroupTab === 0 && selectedSubTab === 0"
            class="singlecategorydashboard--tab--summary"
          >
            <v-row class="singlecategorydashboard--summary ma-0">
              <v-col
                class="pa-0 px-2 col-md-4 col-sm-12 mb-md-3 mb-sm-3"
                cols="4"
              >
                <div class="singlecategorydashboard--summary--box pa-6">
                  <r-big-number
                    :loading="fetchingDashboardsData"
                    :title="$t('dashboards.singleBCT.components.totalNumReviewsTitle')"
                    :value="numReviews"
                    :previous-value="0"
                  />
                  <v-divider class="my-12" />
                  <r-time-line-chart
                    :title="$t('dashboards.singleBCT.components.numReviewsOverTime')"
                    spline
                    :duration-gap="timeLineChartDurationGap"
                    :data="[{
                      chartType: 'column',
                      seriesData: timeLineChartSeriesData,
                    }]"
                    :height="120"
                    @click:point="onClickTimeLineChartPoint"
                  />
                  <v-divider class="my-12" />
                  <r-rating-time-line-chart
                    :title="$t('dashboards.singleBCT.components.averageStarRatingOverTime')"
                    :loading="fetchingDashboardsData"
                    :max-rate-value="5"
                    :data="netRatingHistorySeriesData"
                    @click:rating="onClickRating"
                  />
                </div>
              </v-col>
              <v-col
                class="pa-0 px-2 col-md-4 col-sm-12 mb-md-3 mb-sm-3"
                cols="4"
              >
                <div class="singlecategorydashboard--summary--box pa-6">
                  <r-star-rating-distribution-chart
                    :title="$t('dashboards.singleBCT.components.starRatingDistribution')"
                    :loading="fetchingDashboardsData"
                    :data="starRatingDistribution"
                    @click:rating="onClickRating"
                  />
                  <v-divider class="my-12" />
                  <r-net-sentiment-half-donut
                    :title="$t('dashboards.singleBCT.components.netSentimentDistribution')"
                    :loading="fetchingDashboardsData"
                    :net-sentiment="netSentiment"
                    :num-negative="sentimentBreakdown.negative"
                    :num-neutral="sentimentBreakdown.neutral"
                    :num-positive="sentimentBreakdown.positive"
                    @click:sentiment="onClickSentiment"
                  />
                </div>
              </v-col>
              <v-col
                class="pa-0 px-2 col-md-4 col-sm-12 mb-md-3 mb-sm-3"
                cols="4"
              >
                <div class="singlecategorydashboard--summary--box pa-6">
                  <r-top-urls
                    :highest-rated="highestRatedUrls"
                    :most-reviewed="mostReviewedUrls"
                  />
                  <v-divider class="my-10" />
                  <r-trends
                    class="singlecategorydashboard--rtrends"
                    :days="trendsSelectedDays"
                    :loading="fetchingDashboardsData || fetchingTrends"
                    :total-positive="trends.totalPositive"
                    :total-negative="trends.totalNegative"
                    :positive-percent-increase="trends.positivePercentIncrease"
                    :negative-percent-increase="trends.negativePercentIncrease"
                    :positive-data="trends.positiveData"
                    :negative-data="trends.negativeData"
                    :positive-entities="trends.positiveEntities"
                    :negative-entities="trends.negativeEntities"
                    @change:daterange="handleTrendsDateChange"
                    @click:entity-sentiment="onClickEntitySentiment"
                    @click:entity="onClickEntity"
                  />
                </div>
              </v-col>
            </v-row>

            <v-row class="singlecategorydashboard--sites mx-0">
              <v-col class="pa-0">
                <r-sites-analyzed
                  :data="sites"
                  :loading="fetchingDashboardsData"
                  @click:hostname="onClickHostname"
                />
              </v-col>
            </v-row>

            <v-row class="singlecategorydashboard--comparison-chart mx-0">
              <v-col class="pa-0">
                <r-comparison-chart
                  :loading="fetchingDashboardsData"
                  :data="comparisonChartData.data"
                  :breakdown-keys="comparisonChartData.breakdownKeys"
                  @change:series-one="onChangeComparisonSeriesOne"
                  @change:series-two="onChangeComparisonSeriesTwo"
                  @click:series="onClickComparisonChartSeries"
                />
              </v-col>
            </v-row>
          </v-col>

          <!-- Trending Tab -->
          <v-col
            v-if="selectedGroupTab === 0 && selectedSubTab === 1"
          >
            <v-row
              class="singlecategorydashboard--tab--trending ma-0"
            >
              <v-col
                class="pa-0"
                cols="12"
              >
                <r-component-header class="rcomponentheader--bordered">
                  {{ $t('dashboards.components.trending') }}
                </r-component-header>
                <r-rating-time-line-comparison
                  :data="getUrlsHistory"
                  @change:sort="onChangeOfRatingComparisonSort"
                  @change:num="onChangeOfRatingComparisonDays"
                />
                <trending-data
                  :trending-data="comparisonTrendingData"
                  :loading="fetchingDashboardsData || fetchingDashboardsTrendingData"
                  @change:period-date-range="onChangeTrendingPeriodDate"
                  @click:sentiment="onClickSentiment"
                  @click:entity-name="onClickEntityName"
                  @click:word="onClickWord"
                  @click:rating="onClickRating"
                />
              </v-col>
            </v-row>
          </v-col>

          <!-- Entity Tab -->
          <v-col
            v-if="selectedGroupTab === 0 && selectedSubTab === 2"
          >
            <v-row
              class="singlecategorydashboard--tab--entity ma-0"
            >
              <v-col
                class="pa-0"
                cols="12"
              >
                <r-component-header class="rcomponentheader--bordered">
                  {{ $t('dashboards.components.wordCloud') }}
                  <template #end>
                    <div class="d-flex align-center">
                      <r-button
                        v-if="entityMapIsApplied"
                        class="wordcloudheaderbutton mr-2"
                        label="Add to entity map"
                        outlined
                        :disabled="selectedWords.length === 0"
                        @click="onClickAddToEntityMap"
                      />
                      <r-button
                        v-if="entityMapIsApplied"
                        class="wordcloudheaderbutton"
                        label="Irrelevant"
                        icon="delete"
                        outlined
                        :disabled="selectedWords.length === 0"
                        @click="onClickIgnore"
                      />
                      <r-anchored-popup
                        v-slot="{ onClose }"
                        class="wordcloudheaderbutton entitysettingspopup"
                        icon="mdi-cog"
                        label=""
                        fab-icon
                        small-button
                      >
                        <entity-settings
                          :applied-color-by-filter="appliedColorByFilter"
                          :applied-highlight-filter="appliedHighlightFilter"
                          :applied-separate-by-filter="appliedSeparateByFilter"
                          @apply="onApplyEntitySettingsFilter"
                          @close="onClose"
                        />
                      </r-anchored-popup>
                    </div>
                  </template>
                </r-component-header>
                <r-word-cloud
                  v-if="showSingleCloudWord"
                  :entities="wordCloudData"
                  :color="appliedColorByFilter"
                  :time-buckets="timeBuckets"
                  :disable-entity-map-controls="!entityMapIsApplied"
                  :loading-data="fetchingWordCloudData"
                  @change:selected-words="onChangeSelectedWords"
                  @click:edit-entity-map-group="onClickEditEntityMapGroup"
                  @click:word="onClickWord"
                />
                <r-word-cloud-grouped
                  v-else
                  :applied-color-by-filter="appliedColorByFilter"
                  :applied-highlight-filter="appliedHighlightFilter"
                  :applied-separate-by-filter="appliedSeparateByFilter"
                  :entity-map-is-applied="entityMapIsApplied"
                  :is-getting-entities="fetchingWordCloudData"
                  :time-buckets="timeBuckets"
                  :word-cloud-grouped="wordCloudGroupedData"
                  @change:selected-words="onChangeSelectedWords"
                  @click:edit-entity-map-group="onClickEditEntityMapGroup"
                  @click:word="onClickWord"
                />
                <KeyColor
                  v-if="appliedColorByFilter"
                  :applied-colors="appliedColors"
                  :reversed-keys="appliedColorByFilter === 'rating'"
                />
              </v-col>
              <v-col
                class="pa-0"
                cols="12"
              >
                <r-component-header class="rcomponentheader--bordered">
                  {{ $t('dashboards.components.entityProgress') }}
                </r-component-header>
                <entity-progress
                  :loading="fetchingDashboardsData"
                  :width="entityProgressBarChartWidth"
                  @click:entity="onClickEntity"
                />
              </v-col>
              <v-col
                class="pa-0"
                cols="12"
              >
                <r-component-header class="rcomponentheader--bordered">
                  {{ $t('dashboards.components.topicWheel') }}
                </r-component-header>
                <r-topic-wheel
                  title=""
                  :hierarchical="true"
                  :skeleton-size="370"
                  :data="topicWheelData"
                  :loading="fetchingDashboardsEntitiesWithGroupData"
                  @click:entity-name="onClickEntityName"
                />
              </v-col>
            </v-row>
          </v-col>

          <!-- Location Tab -->
          <v-col
            v-if="selectedGroupTab === 0 && selectedSubTab === 3"
          >
            <v-row
              class="singlecategorydashboard--tab--location ma-0"
            >
              <v-col
                class="pa-0"
                cols="12"
              >
                <r-component-header class="rcomponentheader--bordered">
                  {{ $t('dashboards.components.location') }}
                </r-component-header>
              </v-col>
              <v-col
                class="pa-0"
                cols="6"
              >
                <r-map
                  :loading="fetchingDashboardsData"
                  :data="locationsMap"
                  @click:country="onClickMapCountry"
                />
              </v-col>
              <v-col
                class="pa-0"
                cols="6"
              >
                <r-sentiment-bar-chart
                  :loading="fetchingDashboardsData"
                  :data="locationsChart"
                  :width="barChartWidth"
                  @click:entity="onClickLocationEntity"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>

      <!-- Reviews Panel -->
      <div
        v-show="showReviews"
        ref="reviewscardsection"
        cols="3"
        class="reviewscardsection pa-0"
      >
        <r-review-card-list
          :key="`review-card-list-${showReviews}`"
          :reviews="reviews"
          :total-reviews="numReviews"
          :loading="fetchingReviews"
          :page="page"
          :page-size="pageSize"
          :total-pages="reviewsTotalPage"
          :search="appliedFilters.search"
          :applied-entities="appliedEntityFilters"
          @change:page="onReviewsPageChange"
          @change:sort="onReviewsSortChange"
          @close="() => onShowReviews({ value: false })"
        />
      </div>
    </v-row>

    <edit-entity-group-modal
      v-if="showEditEntityMap"
      :entities="$data.selectedWords.map(({ label }) => label)"
      :entity-group-id="editEntityMapGroupId"
      @close="showEditEntityMap = false"
      @submit="onClickSubmitEntityGroupModal"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import buildComparisonChartData from '@/utils/buildComparisonChartData'
import DashboardHeader from '@/components/app/dashboards/common/DashboardHeader'
import RComponentHeader from '@/components/library/atoms/RComponentHeader'
import RTimeLineChart from '@/components/library/atoms/RTimeLineChart'
import RBigNumber from '@/components/library/molecules/RBigNumber'
import RNetSentimentHalfDonut from '@/components/library/molecules/RNetSentimentHalfDonut'
import RStarRatingDistributionChart from '@/components/library/molecules/RStarRatingDistributionChart'
import RSitesAnalyzed from '@/components/library/molecules/RSitesAnalyzed'
import RComparisonChart from '@/components/library/organisms/RComparisonChart'
import RTrends from '@/components/library/molecules/RTrends'
import RGroupedTabs from '@/components/library/molecules/RGroupedTabs'
import RRatingTimeLineChart from '@/components/library/atoms/RRatingTimeLineChart'
import RWordCloud from '@/components/library/organisms/RWordCloud'
import RMap from '@/components/library/atoms/RMap'
import RSentimentBarChart from '@/components/library/molecules/RSentimentBarChart'
import RTopicWheel from '@/components/library/molecules/RTopicWheel'
import RReviewCardList from '@/components/library/molecules/RReviewCardList'
import DashboardFilterChips from '@/components/app/dashboards/DashboardFilterChips'
import RTopUrls from '@/components/library/atoms/RTopUrls'
import RRatingTimeLineComparison from '@/components/library/molecules/RRatingTimeLineComparison'
import TrendingData from '@/components/app/dashboards/TrendingData'
import DashboardDateSelector from '@/components/app/dashboards/DashboardDateSelector'
import EditEntityGroupModal from '@/components/app/dashboards/EditEntityGroupModal'
import RButton from '@/components/library/atoms/RButton'
import EntityProgress from '@/components/app/dashboards/EntityProgress'
import RAnchoredPopup from '@/components/library/molecules/RAnchoredPopup.vue'
import EntitySettings from '@/components/app/dashboards/EntitySettings.vue'
import RWordCloudGrouped from '@/components/app/dashboards/RWordCloudGrouped.vue'
import KeyColor from '@/components/app/dashboards/KeyColor.vue'

import {
  COMPARISON_CHART_DATA_TYPES,
  SCROLL_RESET_POSITION,
  SINGLE_CATEGORY_GROUPED_TABS,
} from '@/utils/constants'

import { generateColorMap, generateTimeBuckets } from './utils'

const PADDING_X = 112
const STACKED_LABEL_TEXT_WIDTH = 100

export default {
  name: 'SingleCategoryDashboard',
  components: {
    KeyColor,
    RWordCloudGrouped,
    DashboardDateSelector,
    RComponentHeader,
    RTimeLineChart,
    RBigNumber,
    RNetSentimentHalfDonut,
    RStarRatingDistributionChart,
    DashboardHeader,
    RSitesAnalyzed,
    RComparisonChart,
    RTrends,
    RGroupedTabs,
    RRatingTimeLineChart,
    RWordCloud,
    RMap,
    RSentimentBarChart,
    RTopicWheel,
    RReviewCardList,
    DashboardFilterChips,
    RTopUrls,
    RRatingTimeLineComparison,
    TrendingData,
    EditEntityGroupModal,
    RButton,
    EntityProgress,
    RAnchoredPopup,
    EntitySettings,
  },
  data: () => ({
    SINGLE_CATEGORY_GROUPED_TABS,
    showReviews: false,
    page: 1,
    pageSize: 50,
    sort: 'date',
    sortDir: 'desc',
    comparisonSeriesOneValue: 'volume',
    comparisonSeriesOneType: COMPARISON_CHART_DATA_TYPES.AGGREGATE,
    comparisonSeriesTwoValue: 'sentiment',
    comparisonSeriesTwoType: COMPARISON_CHART_DATA_TYPES.BREAKDOWN,
    selectedGroupTab: 0,
    selectedSubTab: 0,
    ratingTimelineComparison: {
      minDate: null,
      maxDate: null,
      sort: 'averageRating',
      sortDir: 'desc',
    },
    startDate: '',
    endDate: '',
    trendsSelectedDays: 7,
    selectedWords: [],
    editEntityMapGroupId: null,
    showEditEntityMap: false,
    entityProgressBarChartWidth: 0,
    barChartWidth: 0,
    appliedSeparateByFilter: 'none',
    appliedHighlightFilter: 'none',
    appliedColorByFilter: 'netSentiment',
    timeBucketIntervals: [],
    timeBuckets: [],
  }),
  computed: {
    ...mapGetters('dashboardsData', [
      'name',
      'numReviews',
      'pinned',
      'starRatingDistribution',
      'sentimentBreakdown',
      'netSentiment',
      'reviews',
      'sites',
      'reviewsVolumeHistory',
      'reviewsNetRatingHistory',
      'reviewsSentimentHistory',
      'reviewsRatingDistributionHistory',
      'dashboardId',
      'comparisonTrendingData',
      'wordCloudData',
      'groupedWordCloudData',
      'topicWheelData',
      'sentimentBarChartData',
      'timeLineChartDurationGap',
      'timeLineChartSeriesData',
      'netRatingHistorySeriesData',
      'dashboardDataStartDate',
      'dashboardDataEndDate',
      'locationsChart',
      'locationsMap',
      'highestRatedUrls',
      'mostReviewedUrls',
      'getUrlsHistory',
    ]),
    ...mapGetters('dashboardsFilters', [
      'appliedFilters',
      'appliedEntityFilters',
      'appliedTrendingDataFilters',
      'dateRange',
      'dateRangeIsToday',
      'dateRangeIsOneWeek',
      'dateRangeIsTwoWeek',
      'dateRangeIs30Days',
      'dateRangeIs60Days',
      'dateRangeAllIsSelected',
    ]),
    ...mapState('dashboardsData', [
      'fetchingDashboardsData',
      'fetchingReviews',
      'entities',
      'trends',
      'fetchingTrends',
      'fetchingDashboardsEntityGroups',
      'fetchingDashboardsEntities',
      'fetchingDashboardsEntitiesWithGroupData',
      'fetchingDashboardsReviewsHistory',
      'fetchingDashboardsReviewsNetRatingHistory',
      'fetchingDashboardsSentimentHistory',
      'fetchingDashboardsReviewsRatingHistory',
      'urlBreakdown',
      'fetchingDashboardsTrendingData',
      'urls',
    ]),
    ...mapGetters('entityMaps', [
      'entityMapIsApplied',
    ]),
    fetchingWordCloudData() {
      // eslint-disable-next-line max-len
      return this.fetchingDashboardsData || this.fetchingDashboardsEntities || this.fetchingDashboardsEntityGroups
    },
    wordCloudGroupedData() {
      return this.groupedWordCloudData(this.appliedHighlightFilter)
    },
    reviewsTotalPage() {
      return Math.floor(this.numReviews / this.pageSize)
    },
    comparisonChartData() {
      const { data: seriesOneData } = buildComparisonChartData({
        type: this.comparisonSeriesOneType,
        data: this.getComparisonData({ dataType: this.comparisonSeriesOneValue }),
      })
      const { data: seriesTwoData, breakdownKeys } = buildComparisonChartData({
        type: this.comparisonSeriesTwoType,
        data: this.getComparisonData({ dataType: this.comparisonSeriesTwoValue }),
      })

      return {
        data: [
          seriesOneData,
          seriesTwoData,
        ],
        breakdownKeys: breakdownKeys.map(({ labelKey }) => ({
          label: this.$t(`global.keys.${labelKey}`),
          labelKey,
        })),
      }
    },
    showSingleCloudWord() {
      const groupedData = this.groupedWordCloudData()
      const hasSeparateByFilter = !!this.appliedSeparateByFilter && this.appliedSeparateByFilter !== 'none'
      const hasGroupedData = groupedData && Object.keys(groupedData).length

      return !hasSeparateByFilter || !hasGroupedData
    },
    appliedColors() {
      return generateColorMap({
        colorBy: this.appliedColorByFilter,
        timeBucketIntervals: this.timeBucketIntervals,
      })
    },
  },
  watch: {
    async appliedFilters() {
      this.page = 1
      await this.fetchData()
    },
    appliedTrendingDataFilters(newFilters, oldFilters) {
      const { trendingDataDateRangeFilter: newTrendingDates } = newFilters
      const { trendingDataDateRangeFilter: oldTrendingDates } = oldFilters

      const { lastPeriod: newLastPeriod } = newTrendingDates
      const { lastPeriod: oldLastPeriod } = oldTrendingDates
      if (JSON.stringify(newLastPeriod) !== JSON.stringify(oldLastPeriod)) {
        this.fetchComparisonTrendingData({ period: 'lastPeriod' })
      }

      const { thisPeriod: newThisPeriod } = newTrendingDates
      const { thisPeriod: oldThisPeriod } = oldTrendingDates
      if (JSON.stringify(newThisPeriod) !== JSON.stringify(oldThisPeriod)) {
        this.fetchComparisonTrendingData({ period: 'thisPeriod' })
      }
    },
    dateRange() {
      const { minDate, maxDate } = this.appliedFilters
      if (!minDate || !maxDate) {
        return this.loadDashboardTimeBuckets()
      }
      this.startDate = minDate
      this.endDate = maxDate
      return this.setTimeBuckets({ minDate, maxDate })
    },
  },
  async mounted() {
    this.setEntityProgressBarChartWidth()
    this.setBarchartWidth()
    await this.fetchData()
    if (this.dateRangeAllIsSelected) {
      this.startDate = this.dashboardDataStartDate
      this.endDate = this.dashboardDataEndDate
    }
    window.addEventListener('resize', this.setDimensions)
  },
  beforeDestroy() {
    this.resetState()
    window.removeEventListener('resize', this.setDimensions)
  },
  methods: {
    ...mapActions('dashboardsData', [
      'fetchDashboardsData',
      'fetchDashboardsReviews',
      'fetchDashboardsTrends',
      'fetchDashboardsReviewHistory',
      'fetchDashboardsReviewsNetRatingHistory',
      'fetchDashboardsReviewsSentimentHistory',
      'fetchDashboardsReviewsRatingHistory',
      'fetchDashboardsUrlsHistory',
      'fetchTrendingData',
      'fetchDashboardsEntities',
      'fetchDashboardEntityGroups',
      'fetchDashboardsEntitiesWithGroupData',
    ]),
    ...mapActions('dashboardsFilters', [
      'setDashboardFilters',
    ]),
    ...mapActions('entityMaps', [
      'addIgnore',
      'resetState',
    ]),
    setTimeBuckets({ minDate, maxDate }) {
      const { timeBuckets, timeBucketIntervals } = generateTimeBuckets({
        minDate,
        maxDate,
        moment: this.$moment,
      })
      this.timeBuckets = timeBuckets
      this.timeBucketIntervals = timeBucketIntervals
    },
    getComparisonData({ dataType }) {
      const COMPARISON_DATA = {
        volume: this.reviewsVolumeHistory,
        ratingAverage: this.reviewsNetRatingHistory,
        sentiment: this.reviewsSentimentHistory,
        ratingDistribution: this.reviewsRatingDistributionHistory,
      }

      return COMPARISON_DATA[dataType] || []
    },
    fetchComparisonChartData({ key }) {
      const COMPARISON_DATA_FETCHER = {
        volume: this.fetchDashboardsReviewHistory,
        ratingAverage: this.fetchDashboardsReviewsNetRatingHistory,
        sentiment: this.fetchDashboardsReviewsSentimentHistory,
        ratingDistribution: this.fetchDashboardsReviewsRatingHistory,
        default: () => {},
      }

      const fetcher = COMPARISON_DATA_FETCHER[key] || COMPARISON_DATA_FETCHER.default
      return fetcher({ dashboardId: this.dashboardId })
    },
    onShowReviews({ value }) {
      this.showReviews = value
      this.setDimensions()
    },
    onChangeGroupTab(index) {
      this.selectedGroupTab = index
      this.selectedSubTab = undefined
      this.$refs.dashboardcontent.scrollTo(SCROLL_RESET_POSITION)
    },
    onChangeSubTab(index) {
      this.selectedSubTab = index
      this.$refs.dashboardcontent.scrollTo(SCROLL_RESET_POSITION)
    },
    getPaginationPayload() {
      return {
        page: this.page,
        pageSize: this.pageSize,
      }
    },
    getSortingPayload() {
      return {
        sort: this.sort,
        sortDir: this.sortDir,
      }
    },
    fetchReviews() {
      const pagination = this.getPaginationPayload()
      const sorting = this.getSortingPayload()
      this.fetchDashboardsReviews({ dashboardId: this.dashboardId, pagination, sorting })
    },
    onChangeComparisonSeriesOne({ value }) {
      const { key, type } = value

      this.comparisonSeriesOneValue = key
      this.comparisonSeriesOneType = type

      this.fetchComparisonChartData({ key })
    },
    onChangeComparisonSeriesTwo({ value }) {
      const { key, type } = value

      this.comparisonSeriesTwoValue = key
      this.comparisonSeriesTwoType = type

      this.fetchComparisonChartData({ key })
    },
    async onReviewsPageChange({ value }) {
      this.page = value
      this.fetchReviews()
    },
    async onReviewsSortChange({ value }) {
      const { sort, sortDir } = value
      this.sort = sort
      this.sortDir = sortDir
      this.fetchReviews()
    },
    async handleTrendsDateChange({ value }) {
      this.trendsSelectedDays = value
      const maxDate = this.$moment().format('YYYY-MM-DD')
      const minDate = this.$moment().subtract(value - 1, 'days').format('YYYY-MM-DD')
      this.fetchDashboardsTrends({ dashboardId: this.dashboardId, minDate, maxDate })
    },
    loadDashboardTimeBuckets() {
      if (this.dateRangeAllIsSelected && this.dashboardDataStartDate) {
        this.setTimeBuckets({
          minDate: this.dashboardDataStartDate,
          maxDate: this.dashboardDataEndDate,
        })
      }
    },
    async fetchData() {
      this.fetchReviews()
      this.fetchComparisonChartData({ key: this.comparisonSeriesOneValue })
      this.fetchComparisonChartData({ key: this.comparisonSeriesTwoValue })
      this.fetchTopicWheelData()
      this.fetchWordCloudData()

      await this.fetchDashboardsData({ dashboardId: this.dashboardId })
      this.loadDashboardTimeBuckets()
    },
    async onChangeOfRatingComparisonDays({ value }) {
      const maxDate = this.$moment().format('YYYY-MM-DD')
      const minDate = this.$moment().subtract(value - 1, 'days').format('YYYY-MM-DD')
      this.ratingTimelineComparison = {
        ...this.ratingTimelineComparison,
        minDate,
        maxDate,
      }
      this.fetchDashboardsUrlsHistory({
        dashboardId: this.dashboardId,
        ...this.ratingTimelineComparison,
      })
    },
    async onChangeOfRatingComparisonSort({ value }) {
      this.ratingTimelineComparison = {
        ...this.ratingTimelineComparison,
        sortDir: value,
      }
      this.fetchDashboardsUrlsHistory({
        dashboardId: this.dashboardId,
        ...this.ratingTimelineComparison,
      })
    },
    async onChangeTrendingPeriodDate({ value }) {
      const { dateRange, period } = value

      this.setDashboardFilters({
        trendingDataDateRangeFilter: {
          [period]: { ...dateRange },
        },
      })
    },
    fetchComparisonTrendingData({ period }) {
      this.fetchTrendingData({ dashboardId: this.dashboardId, period })
    },
    onChangeDateRange({ dateRange }) {
      this.setDashboardFilters({ dateRange })

      if (!dateRange) {
        return
      }

      const [startDate, endDate] = dateRange
      this.startDate = startDate
      this.endDate = endDate
    },
    onClickSentiment({ value }) {
      this.setDashboardFilters({ sentiment: value })
    },
    onClickMapCountry({ value }) {
      const { clickedCountry } = value
      this.setDashboardFilters({ countries: [clickedCountry] })
    },
    onClickRating({ value }) {
      this.setDashboardFilters({ ratings: [value] })
    },
    onClickComparisonChartSeries({ value }) {
      const {
        clickedSeries,
        clickedValue,
        dateFrom: startDate,
        dateTo: endDate,
      } = value

      let filterPayload = { dateRange: [startDate, endDate] }

      if (clickedSeries === 'ratingDistribution') {
        const rating = parseInt(clickedValue, 10)
        filterPayload = {
          ...filterPayload,
          ratings: [rating],
        }
      }

      if (clickedSeries === 'sentiment') {
        filterPayload = {
          ...filterPayload,
          sentiment: clickedValue.toUpperCase(),
        }
      }

      this.setDashboardFilters(filterPayload)
    },
    onClickTimeLineChartPoint({ value }) {
      this.setDashboardFilters({ dateRange: value })
    },
    onClickHostname({ value }) {
      this.setDashboardFilters({ hostnames: [value] })
    },
    onClickLocationEntity({ value: { label, entitySentiment } }) {
      this.setDashboardFilters({ countries: [label], sentiment: entitySentiment })
    },
    onClickEntity({ value }) {
      const { label, entitySentiment, entityGroupId } = value
      if (entityGroupId) {
        this.setDashboardFilters({ entityGroupId, entitySentiment })
      } else {
        this.setDashboardFilters({ entity: label, entitySentiment })
      }
    },
    onClickEntitySentiment({ value }) {
      this.setDashboardFilters({ entitySentiment: value })
    },
    onClickEntityName({ value }) {
      const { label, entityGroupId } = value
      if (entityGroupId) {
        this.setDashboardFilters({ entityGroupId })
      } else {
        this.setDashboardFilters({ entity: label })
      }
    },
    onChangeSelectedWords({ value: newSelectedWords }) {
      this.$data.selectedWords = newSelectedWords
    },
    onClickEditEntityMapGroup({ value }) {
      this.$data.editEntityMapGroupId = value
      this.$data.showEditEntityMap = true
    },
    onClickAddToEntityMap() {
      this.$data.editEntityMapGroupId = null
      this.$data.showEditEntityMap = true
    },
    async fetchWordCloudData() {
      if (this.showSingleCloudWord) {
        return this.fetchDashboardsEntities()
      }

      return this.fetchDashboardEntityGroups({
        separateBy: this.appliedSeparateByFilter,
        urls: this.urls,
        timeBuckets: this.timeBuckets,
      })
    },
    fetchTopicWheelData() {
      return this.fetchDashboardsEntitiesWithGroupData()
    },
    async onClickIgnore() {
      const ignore = this.$data.selectedWords.map(({ label }) => label)
      this.$data.selectedWords = []
      await this.addIgnore({ ignore })
      this.fetchWordCloudData()
      return this.fetchTopicWheelData()
    },
    onClickWord({ value }) {
      const { label, entityGroupId } = value
      if (entityGroupId) {
        this.setDashboardFilters({ entityGroupId })
      } else {
        this.setDashboardFilters({ entity: label })
      }
    },
    setBarchartWidth() {
      const reviewsWidth = this.$refs.reviewscardsection?.offsetWidth
      this.barChartWidth = this.$data.showReviews
        ? (window.innerWidth - PADDING_X - STACKED_LABEL_TEXT_WIDTH - reviewsWidth) / 2
        : (window.innerWidth - PADDING_X - STACKED_LABEL_TEXT_WIDTH) / 2
    },
    setEntityProgressBarChartWidth() {
      const reviewsWidth = this.$refs.reviewscardsection?.offsetWidth
      this.entityProgressBarChartWidth = this.$data.showReviews
        ? (window.innerWidth - PADDING_X - STACKED_LABEL_TEXT_WIDTH - reviewsWidth)
        : (window.innerWidth - PADDING_X - STACKED_LABEL_TEXT_WIDTH)
    },
    async setDimensions() {
      await this.$nextTick()
      this.setBarchartWidth()
      this.setEntityProgressBarChartWidth()
    },
    onClickSubmitEntityGroupModal() {
      this.fetchTopicWheelData()
      this.fetchWordCloudData()
      this.$data.selectedWords = []
    },
    async onApplyEntitySettingsFilter({ separateBy, highlight, colorBy }) {
      if (separateBy === 'none') {
        this.wordCloudGrouped = {}
        this.appliedSeparateByFilter = 'none'
        this.appliedHighlightFilter = 'none'
        return
      }

      if (separateBy && this.appliedSeparateByFilter !== separateBy) {
        this.appliedSeparateByFilter = separateBy
        await this.fetchDashboardEntityGroups({
          separateBy: this.appliedSeparateByFilter,
          urls: this.urls,
          timeBuckets: this.timeBuckets,
        })
      }

      if (highlight && this.appliedHighlightFilter !== highlight) {
        this.appliedHighlightFilter = highlight
      }

      if (colorBy && this.appliedColorByFilter !== colorBy) {
        this.appliedColorByFilter = colorBy
      }
    },
  },
}
</script>

<style scoped>
.dashboardcontent {
  max-height: calc(100vh - 210px);
  overflow-y: auto;
}
.singlecategorydashboard--header {
  margin-top: 24px;
  margin-bottom: 16px;
}
.singlecategorydashboard--tabs {
  margin-top: 16px;
  margin-bottom: 22px;
  border-bottom: 1px solid var(--r-border-color);
}
.singlecategorydashboard--filter-row {
  margin-top: 20px;
  margin-bottom: 20px;
}
.singlecategorydashboard--summary--box {
  border: 1px solid var(--r-border-color);
  height: 702px;
  overflow: hidden;
}
.singlecategorydashboard--sites {
  margin-top: 82px;
  margin-bottom: 44px;
}
.rcomponentheader--bordered {
  border-bottom: 1px solid var(--r-border-color);
  padding-bottom: 8px;
  margin-bottom: 8px;
}
/deep/ .rpageheader {
  border-bottom: 0;
}
/deep/ .v-skeleton-loader__image {
  height: 1000px;
}
/deep/ .singlecategorydashboard--tabs .v-skeleton-loader__button  {
  height: 28px;
}
/deep/ .singlecategorydashboard--filter-row .v-skeleton-loader__button,
/deep/ .singlecategorydashboard--filter-row .v-skeleton-loader__chip {
  height: 24px;
}
.reviewscardsection {
  background-color: white;
  width: 25%;
}
/* overriding margin above positive and negative graph in RTrends component*/
/deep/ .rtrends.singlecategorydashboard--rtrends .rtrends--mt {
  margin-top: 38px;
}
.entitysettingspopup {
  z-index: 1;
}
.wordcloudheaderbutton {
  margin-top: -1rem;
}
.circle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
}
</style>
