var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "productcards--container"
  }, [_vm.loading ? _vm._l(5, function (n) {
    return _c('v-col', {
      key: n,
      staticClass: "productcard--item px-1"
    }, [_c('v-skeleton-loader', {
      attrs: {
        "type": "image",
        "width": "100%",
        "height": "40"
      }
    })], 1);
  }) : _vm._l(_vm.products, function (product, index) {
    return _c('v-col', {
      key: "".concat(product.name, "-").concat(product._id),
      staticClass: "productcard--item px-0"
    }, [_c('r-product-card', {
      attrs: {
        "id": product.productId,
        "name": product.name,
        "color": _vm.getColor(index),
        "is-selected": _vm.selectedProductIds.includes(product.productId),
        "is-hovered": _vm.hoveredProductId === product.productId
      },
      on: {
        "click": _vm.onClickProductCard,
        "mouseenter": _vm.onHoverIn,
        "mouseleave": _vm.onHoverOut
      }
    })], 1);
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }