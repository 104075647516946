var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "dashboardheader ma-0"
  }, [_c('v-col', {
    staticClass: "pa-0"
  }, [_c('r-page-header', {
    attrs: {
      "label": _vm.name,
      "previousroute": "/dashboards"
    },
    scopedSlots: _vm._u([{
      key: "postlabel",
      fn: function fn() {
        return [_c('r-button', {
          staticClass: "ml-2 mr-2 dashboardheader--fixheight",
          attrs: {
            "label": "Save",
            "icon": "save",
            "outlined": ""
          },
          on: {
            "click": function click($event) {
              _vm.showSaveModal = true;
            }
          }
        }), _c('r-button', {
          staticClass: "mr-16 dashboardheader--fixheight",
          attrs: {
            "label": "Save as...",
            "icon": "save",
            "outlined": ""
          },
          on: {
            "click": function click($event) {
              _vm.showSaveAsModal = true;
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "end",
      fn: function fn() {
        return [_c('r-search-input', {
          staticClass: "mr-2 dashboardheader--fixheight",
          staticStyle: {
            "width": "204px"
          },
          attrs: {
            "placeholder": "Search"
          },
          on: {
            "change": _vm.onSearchChange
          }
        }), _c('v-divider', {
          staticClass: "ml-1",
          attrs: {
            "vertical": ""
          }
        }), _c('r-anchored-popup', {
          staticClass: "my-1 mx-1 dashboardheader--filters",
          attrs: {
            "label": _vm.$t('dataFilters.label'),
            "icon": "filter_list"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref) {
              var onClose = _ref.onClose;
              return [_c('dashboard-filters', {
                on: {
                  "close": onClose
                }
              })];
            }
          }])
        }), _c('r-button', {
          staticClass: "dashboardheader--fixheight",
          attrs: {
            "label": _vm.showReviewLabel,
            "icon": "star",
            "outlined": !_vm.showReviews
          },
          on: {
            "click": _vm.onClickShowReviews
          }
        })];
      },
      proxy: true
    }])
  })], 1), _vm.showSaveModal ? _c('save-dashboard-modal', {
    on: {
      "close": function close($event) {
        _vm.showSaveModal = false;
      }
    }
  }) : _vm._e(), _vm.showSaveAsModal ? _c('save-dashboard-as-modal', {
    on: {
      "close": function close($event) {
        _vm.showSaveAsModal = false;
      }
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }