var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "entityprogresstimelinechart"
  }, [_vm.loading ? _c('v-skeleton-loader', {
    staticClass: "pa-1",
    attrs: {
      "type": "image",
      "width": _vm.width,
      "height": "400"
    }
  }) : _vm._e(), _c('highcharts', {
    staticClass: "entityprogresstimelinechart--serieschart",
    attrs: {
      "options": _vm.chartOptions
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }