var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "productcard mx-1 pa-3 d-flex align-center justify-start",
    class: {
      active: _vm.isSelected,
      hovered: _vm.isHovered
    },
    attrs: {
      "title": _vm.name
    },
    on: {
      "click": function click() {
        return _vm.onClickProductCard({
          value: _vm.id
        });
      },
      "mouseenter": function mouseenter() {
        return _vm.onHoverIn({
          value: _vm.id
        });
      },
      "mouseleave": function mouseleave() {
        return _vm.onHoverLeave({
          value: _vm.id
        });
      }
    }
  }, [_c('v-icon', {
    staticClass: "productcard--icon",
    attrs: {
      "color": _vm.color
    }
  }, [_vm._v(" circle ")]), _c('div', {
    staticClass: "productcard--name text-truncate ml-2"
  }, [_vm._v(" " + _vm._s(_vm.name) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }