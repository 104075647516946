var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('r-modal', {
    staticClass: "savedashboardmodal",
    attrs: {
      "title": _vm.$t('dashboards.save.title'),
      "close-action-label": _vm.$t('global.actions.cancel'),
      "action-label": _vm.$t('global.actions.save'),
      "fetching": _vm.fetchingSaveDashboard,
      "error-message": _vm.saveDashboardError
    },
    on: {
      "close": _vm.onClose,
      "submit": _vm.onClickSave
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex justify-center align-center"
        }, [_vm._v(" " + _vm._s(_vm.$t('dashboards.save.confirmMessage')) + " ")])];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }