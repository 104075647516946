<template>
  <v-row class="dashboardheader ma-0">
    <v-col class="pa-0">
      <r-page-header
        :label="name"
        previousroute="/dashboards"
      >
        <template v-slot:postlabel>
          <r-button
            class="ml-2 mr-2 dashboardheader--fixheight"
            label="Save"
            icon="save"
            outlined
            @click="showSaveModal = true"
          />
          <r-button
            class="mr-16 dashboardheader--fixheight"
            label="Save as..."
            icon="save"
            outlined
            @click="showSaveAsModal = true"
          />
        </template>
        <template v-slot:end>
          <r-search-input
            class="mr-2 dashboardheader--fixheight"
            placeholder="Search"
            style="width: 204px"
            @change="onSearchChange"
          />
          <v-divider
            class="ml-1"
            vertical
          />
          <r-anchored-popup
            v-slot="{ onClose }"
            :label="$t('dataFilters.label')"
            icon="filter_list"
            class="my-1 mx-1 dashboardheader--filters"
          >
            <dashboard-filters @close="onClose" />
          </r-anchored-popup>
          <r-button
            class="dashboardheader--fixheight"
            :label="showReviewLabel"
            icon="star"
            :outlined="!showReviews"
            @click="onClickShowReviews"
          />
        </template>
      </r-page-header>
    </v-col>
    <save-dashboard-modal
      v-if="showSaveModal"
      @close="showSaveModal = false"
    />
    <save-dashboard-as-modal
      v-if="showSaveAsModal"
      @close="showSaveAsModal = false"
    />
  </v-row>
</template>

<script>
import RButton from '@/components/library/atoms/RButton'
import RPageHeader from '@/components/library/molecules/RPageHeader'
import RSearchInput from '@/components/library/molecules/RSearchInput'
import DashboardFilters from '@/components/app/dashboards/DashboardFilters'
import RAnchoredPopup from '@/components/library/molecules/RAnchoredPopup'
import SaveDashboardModal from '@/components/app/dashboards/SaveDashboardModal'
import SaveDashboardAsModal from '@/components/app/dashboards/SaveDashboardAsModal'
import { mapActions } from 'vuex'

export default {
  name: 'DashboardHeader',
  components: {
    RButton,
    RPageHeader,
    RSearchInput,
    RAnchoredPopup,
    DashboardFilters,
    SaveDashboardModal,
    SaveDashboardAsModal,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    pinned: {
      type: Boolean,
      required: true,
    },
    showReviews: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    showSaveModal: false,
    showSaveAsModal: false,
  }),
  computed: {
    showReviewLabel() {
      if (this.showReviews) {
        return this.$t('dashboards.headerBtn.hideReviews')
      }

      return this.$t('dashboards.headerBtn.showReviews')
    },
  },
  methods: {
    ...mapActions('dashboardsFilters', [
      'setDashboardFilters',
    ]),
    onClickShowReviews() {
      this.$emit('show:reviews', { value: !this.showReviews })
    },
    onSearchChange({ value }) {
      this.setDashboardFilters({
        search: value,
      })
    },
  },
}
</script>

<style scoped>
.dashboardheader--fixheight {
  height: 32px !important;
}
.dashboardheader--filters {
  margin-top: 3px;
  border-radius: 50%;
  color: white
}

/deep/ .ranchoredpopup.dashboardheader--filters {
  z-index: 3;
}
</style>
