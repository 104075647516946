var render = function () {
  var _this = this;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "\n    trendingdata--trending\n    trendingdata--trending--box\n    ma-0\n    py-4\n    px-5\n  "
  }, [_c('v-col', {
    staticClass: "trendingdata--trendingfilters",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center justify-center my-2"
  }, [_c('div', {
    staticClass: "d-inline position-relative rdaterangepicker--last-period"
  }, [_c('r-chip', {
    staticClass: "mr-2 rdaterangepicker--last-period-chip",
    attrs: {
      "label": "".concat(_vm.lastPeriodStartDate, " - ").concat(_vm.lastPeriodEndDate),
      "textonly": true,
      "icon": "expand_more"
    },
    on: {
      "click": _vm.toggleLastPeriodDateRangePicker
    }
  }), _vm.showLastPeriodDateRangePicker ? _c('r-date-range-picker', {
    attrs: {
      "range": [_vm.lastPeriodStartDate, _vm.lastPeriodEndDate]
    },
    on: {
      "cancel": function cancel($event) {
        _vm.showLastPeriodDateRangePicker = false;
      },
      "change": _vm.onChangeLastPeriodDateRange
    }
  }) : _vm._e()], 1), _c('v-divider', {
    staticClass: "mx-5",
    attrs: {
      "vertical": ""
    }
  }), _c('div', {
    staticClass: "d-inline position-relative rdaterangepicker--this-period"
  }, [_c('r-chip', {
    staticClass: "mr-2 rdaterangepicker--this-period-chip",
    attrs: {
      "label": "".concat(_vm.thisPeriodStartDate, " - ").concat(_vm.thisPeriodEndDate),
      "textonly": true,
      "icon": "expand_more"
    },
    on: {
      "click": _vm.toggleThisPeriodDateRangePicker
    }
  }), _vm.showThisPeriodDateRangePicker ? _c('r-date-range-picker', {
    attrs: {
      "range": [_vm.thisPeriodStartDate, _vm.thisPeriodEndDate]
    },
    on: {
      "cancel": function cancel($event) {
        _vm.showThisPeriodDateRangePicker = false;
      },
      "change": _vm.onChangeThisPeriodDateRange
    }
  }) : _vm._e()], 1)], 1), _c('div', {
    staticClass: "d-flex align-center justify-center my-1"
  }, _vm._l(_vm.quickDateRangeSelectors, function (quickSelector) {
    return _c('r-chip', {
      key: quickSelector.label,
      staticClass: "mr-2 quick--selector",
      attrs: {
        "active": quickSelector.selected,
        "label": quickSelector.label
      },
      on: {
        "click": function click() {
          return _vm.onClickQuickDateSelector(quickSelector.value);
        }
      }
    });
  }), 1)]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "cols": "ma-0"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('trending-column', {
    attrs: {
      "num-reviews": _vm.lastPeriodData.numReviews || 0,
      "net-rating": _vm.lastPeriodData.netRating || 0,
      "net-sentiment": _vm.lastPeriodData.sentiment.netSentiment,
      "sentiment-breakdown": _vm.lastPeriodData.sentiment.sentimentBreakdown,
      "entities": _vm.lastPeriodData.entities,
      "loading": _vm.loading
    },
    on: {
      "click:rating": function clickRating(payload) {
        return _this.$emit('click:rating', payload);
      },
      "click:sentiment": function clickSentiment(eventPayload) {
        return _this.$emit('click:sentiment', eventPayload);
      },
      "click:entity-name": function clickEntityName(payload) {
        return _this.$emit('click:entity-name', payload);
      },
      "click:word": function clickWord(payload) {
        return _this.$emit('click:word', payload);
      }
    }
  })], 1), _c('v-divider', {
    attrs: {
      "vertical": ""
    }
  }), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('trending-column', {
    attrs: {
      "num-reviews": _vm.thisPeriodData.numReviews || 0,
      "net-rating": _vm.thisPeriodData.netRating || 0,
      "net-sentiment": _vm.thisPeriodData.sentiment.netSentiment,
      "sentiment-breakdown": _vm.thisPeriodData.sentiment.sentimentBreakdown,
      "entities": _vm.thisPeriodData.entities,
      "loading": _vm.loading
    },
    on: {
      "click:sentiment": function clickSentiment(eventPayload) {
        return _this.$emit('click:sentiment', eventPayload);
      },
      "click:entity-name": function clickEntityName(payload) {
        return _this.$emit('click:entity-name', payload);
      },
      "click:word": function clickWord(payload) {
        return _this.$emit('click:word', payload);
      },
      "click:rating": function clickRating(payload) {
        return _this.$emit('click:rating', payload);
      }
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }